import {omitBy} from 'lodash'
export * from './url'

/**
 * Returns new object omitting fields with null values,
 * empty strings and arrays, because when adding or updating
 * deposit those fields should not be sent to sever
 * TODO: remove double in store/deposits
 * @param {Object} data
 */
export const omitEmpty = data => omitBy(data, (value, key) => {
  if (key === 'actors' && Array.isArray(value) && !value.length) {
    return value.filter(v => v !== null)
  }
  return value === null || value === ''
})

/**
 * When uploading files
 * @param value
 * @param field
 * @param fd
 * @returns {FormData}
 */
export const makeFormData = (value, field = null, fd = new FormData()) => {
  if (value === null || value === '') {
    // omit empty fields
  } else if (value instanceof File || value instanceof Blob) {
    fd.append(field, value)
  } else if (typeof value === 'object') {
    if (Object.entries(value)) {
      for (let [key, val] of Object.entries(value)) {
        if (val) {
          makeFormData(val, field ? `${field}[${key}]` : key, fd)
        }
      }
    } else {
      makeFormData(value, field)
    }
  } else {
    fd.append(field, value.toString())
  }
  return fd
}

/**
 * Check if string is a percent ("1.0", "0.5", "0.01", etc.)
 * @param {string} str
 * @return {boolean}
 */
export const isPercent = str => {
  return str === '0' || str === '1' || /^\d+\.\d+$/.test(str)
}

/**
 * Check if string is a fraction ("1/2", "3/8", "7/58" etc.)
 * @param {string} str
 * @return {boolean}
 */
export const isFraction = str => {
  return /^\d+\/\d+$/.test(str)
}

/**
 * Converting '0.5' → '1/2', '0.02' → '1/50', etc.
 * @param {string} decimal
 * @param {number} fallbackDenominator
 * @return {string}
 */
export const convertDecimalToFraction = (decimal, fallbackDenominator = 1) => {
  if (['1', '1.0'].includes(decimal)) return `${fallbackDenominator}/${fallbackDenominator}`
  if (decimal === '0') return `0/${fallbackDenominator}`
  if (isNaN(decimal) || !/^\d\.\d+$/.test(decimal)) return ''

  const highestCommonFactor = (a, b) => {
    if (b === 0) return a
    return highestCommonFactor(b, a % b)
  }

  let [leftDecimalPart, rightDecimalPart] = decimal.toString().split('.')

  let numerator = leftDecimalPart + rightDecimalPart
  let denominator = Math.pow(10, rightDecimalPart.length)
  let factor = highestCommonFactor(numerator, denominator)
  denominator /= factor
  numerator /= factor
  return `${numerator}/${denominator}`
}

/**
 * For downloading pdf files
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data.substr(b64Data.indexOf('base64,') + 7))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}
