export const isUrl = (str) => {
  let isValidUrl
  try {
    isValidUrl = new URL(str)
  } catch {
    isValidUrl = false
  }
  return isValidUrl
}

const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
)

export const isHttpUrl = (str) => urlRegex.test(str)
