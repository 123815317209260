import Vue from 'vue'
import {RepositoryFactory} from '@/repositories/_repository-factory'

const applicationRepository = RepositoryFactory.get('application')

export default {
  namespaced: true,
  state: {
    applications: {}
  },
  getters: {
    getApplicationById: ({applications}) => id => {
      return applications[id]
    },
    getAllApplications ({applications}) {
      return Object.values(applications)
    },
    getApplicationByDepositId: (state, {getAllApplications}) => (depositId) => {
      return getAllApplications.find(a => a.deposit_id === Number(depositId))
    }
  },
  mutations: {
    setApplication ({applications}, payload) {
      Vue.set(applications, payload.id, payload)
    },
    updateApplication ({applications}, payload) {
      applications[payload.id] = Object.assign({}, applications[payload.id], payload)
    },
    setApplicationDocuments ({applications}, {id, documents}) {
      applications[id].documents = documents
    }
  },
  actions: {
    async fetchApplicationByDepositId ({commit}, depositId) {
      const {data: {data}} = await applicationRepository.getApplications(depositId)
      /**
       * In case there's more than one application, pick latest,
       * maintaining one-to-one relationship between deposit and application
       */
      const payload = data.sort((a, b) => a.created_at < b.created_at ? 1 : -1)[0]

      if (payload) {
        commit('setApplication', payload)
      }
    },
    async addApplication ({commit}, payload) {
      const {data: {data}} = await applicationRepository.addApplication(payload)
      commit('setApplication', data)
    },
    async updateApplication ({commit}, {id, ...payload}) {
      const {data: {data}} = await applicationRepository.updateApplication(id, payload)
      commit('updateApplication', data)
    },
    async fetchApplicationDocuments ({commit}, applicationId) {
      const {data} = await applicationRepository.getApplicationDocuments(applicationId)
      commit('setApplicationDocuments', {id: applicationId, documents: data})
    },
    async postApplicationDocuments (state, {applicationId, fileData}) {
      await applicationRepository.saveApplicationDocument(applicationId, fileData)
    },
  }
}
