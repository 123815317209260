import Vue from 'vue'

export default {
  profile () {
    return Vue.$http.get('v1/users/profile')
  },
  update (data) {
    return Vue.$http.post('v2/business/users', data)
  },
  updateCompany (data) {
    return Vue.$http.put('v2/business/company/update', data)
  },
  switchToBusinessAccount (data) {
    return Vue.$http.post('v2/business/enquiry', data)
  },
  getEnquiryDetails (id) {
    return Vue.$http.get(`v2/business/enquiry/${id}`)
  },
}
