import Vue from 'vue'
import qs from 'qs'

export default {
  deposits (params) {
    return Vue.$http.get('v2/business/deposits', {
      params,
      paramsSerializer (params) {
        return qs.stringify(params, {encode: false})
      }
    })
  },
  get (id) {
    return Vue.$http.get(`v2/business/deposits/${id}`)
  },
  add (deposit) {
    return Vue.$http.post('v2/business/deposits/add', deposit)
  },
  update (deposit) {
    return Vue.$http.post(`v2/business/deposits/${deposit.id}`, deposit)
  },
  updateWithFiles (id, deposit) {
    return Vue.$http.post(`v2/business/deposits/${id}`, deposit)
  },
  remove (id) {
    return Vue.$http.post(`v2/business/deposits/${id}/delete`)
  },
  arch (id) {
    return Vue.$http.post(`v1/deposits/${id}/arch`)
  },
  register (id) {
    return Vue.$http.post(`v2/business/deposits/${id}/register`)
  },
  certificate (id) {
    return Vue.$http.get(`v2/business/deposits/${id}/pdf`, {
      responseType: 'blob'
    })
  },
  loadTags (q) {
    return Vue.$http.get('v1/tags', {data: {q}})
  },
  uploadChunks (chunk, isLast, filename) {
    const formData = new FormData()
    formData.set('is_last', isLast)
    formData.set('file', chunk, `${filename}.part`)
    return Vue.$http.post('v2/business/deposits/addChunks', formData)
  },
}
