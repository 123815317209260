import application from './application-repository'
import auth from './v1/auth-repository'
import catalog from './catalog-repository'
import config from './v1/config-repository'
import deposits from './deposit-repository'
import projects from './project-repository'
import searchCode from './v1/search-code-repository'
import subscription from './subscription-repository'
import teammates from './teammates-repository'
import user from './v1/user-repository'

import businessUser from './v2/user-repository'

const repositories = {
  auth,
  application,
  catalog,
  config,
  deposits,
  projects,
  searchCode,
  subscription,
  teammates,
  user,
  businessUser,
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
