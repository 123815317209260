import Vue from 'vue'

export default {
  /**
   * @param {{type:('match'|'match_phrase'),search:string,size:number,from:number}} params
   * @return {Promise}
   */
  search (params) {
    return Vue.$http.post('v1/search/code', params)
  },
}
