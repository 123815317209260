import Vue from 'vue'
import {RepositoryFactory} from '@/repositories/_repository-factory'

const authRepository = RepositoryFactory.get('auth')

// TODO consider adding 'auth' module to store and moving it there
export const Auth = {
  options: {
    skipUrls: ['auth'],
    refreshTokenInterval: 300 * 1000,
    onLoginRedirect: {name: 'profile'},
    onLogoutRedirect: {name: 'home'},
  },
  tokenRefresher: 0,
  // eslint-disable-next-line camelcase
  setToken ({token_type, access_token, expires_in}) {
    localStorage.setItem('tokenType', token_type)
    localStorage.setItem('token', access_token)
    // eslint-disable-next-line camelcase
    let expires = (Date.now() + (expires_in * 1000)).toString()
    localStorage.setItem('expires', expires)
  },
  check () {
    let token = localStorage.getItem('token')
    let expires = localStorage.getItem('expires')
    let auth = token && expires > Date.now()
    this.watch.auth = auth
    return auth
  },
  removeToken () {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenType')
    localStorage.removeItem('expires')
  },
  getAuthorizationHeader (url) {
    if (this.options.skipUrls.includes(url)) return false
    if (!this.check()) return false

    let tokenType = localStorage.getItem('tokenType')
    let token = localStorage.getItem('token')
    if (tokenType && token) return tokenType + ' ' + token

    return false
  },
  async refresh () {
    let tokenTimeLeft = localStorage.getItem('expires') - Date.now()
    if (tokenTimeLeft < this.options.refreshTokenInterval * 1.5) {
      try {
        let {data: {data, result}} = await authRepository.refresh()
        this.setAuthenticated(result, data)
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.setAuthenticated(false)
        } else {
          throw e
        }
      }
    } else {
      this.setAuthenticated(true)
    }
  },
  startRefreshCycle () {
    if (!this.tokenRefresher) {
      this.tokenRefresher = setInterval(async () => {
        await this.refresh()
      }, this.options.refreshTokenInterval)
    }
  },
  stopRefreshCycle () {
    clearInterval(this.tokenRefresher)
    this.tokenRefresher = 0
  },
  async authenticate ({code, scope}) {
    this.watch.authenticating = true
    try {
      let {data: {data, result}} = await authRepository.authenticate(code, scope)
      this.setAuthenticated(result, data)
      this.watch.authenticating = false

      this.watch.redirectedFrom = localStorage.getItem('redirectedFrom')
      let url = this.watch.redirectedFrom || this.options.onLoginRedirect
      this.watch.redirectedFrom = null
      return url
    } catch (e) {
      // authentication failed
      this.setAuthenticated(false)
      this.watch.authenticating = false
      throw e
    }
  },
  async logout (onServer = true) {
    try {
      if (onServer) await authRepository.logout()
    } catch (e) {
      console.log(e)
    } finally {
      this.setAuthenticated(false)
    }
  },
  setAuthenticated (auth, tokensData) {
    this.watch.auth = auth
    if (auth) {
      if (tokensData) this.setToken(tokensData)
      this.startRefreshCycle()
    } else {
      this.stopRefreshCycle()
      this.removeToken()
    }
  },
  watch: new Vue({
    data () {
      return {
        auth: false,
        ready: false,
        authenticating: false,
        redirectedFrom: null
      }
    },
    watch: {
      auth (newVal, oldVal) {
        if (newVal && !oldVal) this.$emit('logged-in')
        if (!newVal && oldVal) this.$emit('logged-out')
      }
    },
    created () {
      this.$on('ready', () => {
        this.ready = true
        this.$off('ready')
      })
    }
  })
}

export const AuthPlugin = {
  install (Vue, options = {}) {
    Object.assign(Auth.options, options)
    Vue.prototype.$auth = Auth
    Vue.$auth = Auth
  }
}

const where = (to) => {
  const authRequired = to.meta.auth
  const authed = Vue.$auth.check()

  if (authed) {
    Vue.$auth.watch.redirectedFrom = null
    localStorage.removeItem('redirectedFrom')
  }

  if (!!authRequired === !!authed) {
    return
  }

  if (!authRequired) {
    return Vue.$auth.options.onLoginRedirect
  }

  Vue.$auth.watch.redirectedFrom = to
  localStorage.setItem('redirectedFrom', to.fullPath)
  return {name: 'login'}
}

export const navGuard = async (to, from, next) => {
  if (Vue.$auth.watch.ready) {
    next(where(to, from))
  } else {
    Vue.$auth.watch.$on('ready', () => {
      Vue.$auth.watch.ready = true
      Vue.$auth.watch.$off('ready')
      next(where(to, from))
    })
  }
}
