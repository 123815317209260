<template>
  <div v-if="footer" class="footer-logo">
    <v-img src="../assets/savecode-logo-white.svg" width="144" />
  </div>
  <div v-else class="logos-wrapper mr-4">
    <a :href="homepageUrl" class="order-2 order-sm-1">
      <v-img src="../assets/savecode-logo-white.svg" width="194" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    footer: {type: Boolean},
  },
  data () {
    return {
      homepageUrl: window.env.SUBDOMAIN_LANDING,
    }
  },
}
</script>

<style lang="scss" scoped>
  .logos-wrapper {
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 14px;
  }
</style>
