import Vue from 'vue'

export default {
  async authenticate (code, scope) {
    let response = await Vue.$http.get('v1/auth', {params: {code, scope}})
    response.data.data.accountType = 'business'
    return response
  },
  async refresh () {
    let response = await Vue.$http.post('v1/refresh')
    response.data.data.accountType = 'business'
    return response
  },
  logout () {
    return Vue.$http.post('v1/logout')
  },
  register (user) {
    return Vue.$http.post('v1/register/ipchain_id', user)
  },
  resendVerificationLink () {
    return Vue.$http.post('v1/email/resend')
  },
  sendVerificationCode (phone) {
    return Vue.$http.post('v1/person/phone/send', {phone})
  },
  resendVerificationCode () {
    return Vue.$http.post('v1/person/phone/resend')
  },
  verifyCode (code) {
    return Vue.$http.post('v1/person/phone/verify', {code})
  },
}
