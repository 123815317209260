import {i18n} from '@/plugins/i18n'

const flat = msg => {
  return Array.isArray(msg) ? msg.join(' ') : msg
}

export default {
  namespaced: true,
  state: {
    global: {
      status: false,
      title: '',
      message: '',
      details: '',
      response: '',
    },
    modal: {
      status: false,
      title: '',
      message: '',
      details: '',
      response: '',
    }
  },
  mutations: {
    /**
     *
     * @param state
     * @param {'global'|'modal'} mode
     * @param error
     * @param title
     * @param message
     */
    set: (state, {mode, error = {}, title, message}) => {
      state[mode].status = true
      state[mode].title = title || i18n.t('Sorry, something went wrong')

      if (message) {
        state[mode].message = message
      } else if (error.response?.status === 404) {
        state[mode].title = error.response?.data?.message ||
          i18n.t('Object not found')
      } else if (error.response?.status === 422) {
        let msg = error.response.data.message

        if (Array.isArray(msg)) {
          state[mode].message = flat(msg)
        } else if (typeof msg === 'object') {
          state[mode].message = flat(
            Object.keys(msg)
              .map(key => `<p><b>${key}</b>: ${flat(msg[key])}</p>`)
          )
        } else {
          state[mode].message = msg
        }
      } else if (error.response?.data?.message) {
        state[mode].message = error.response.data.message
      } else {
        state[mode].message = error.message
      }

      // Show request details on local only
      if (window.env.STAGE === 'local' && error.response) {
        let resp = error.response
        state[mode].details = `${error.config.url} — ` +
          `${resp.status} ${resp.statusText}`
        state[mode].response = JSON.stringify(resp.data, null, 2)
      }

      console.log({error})
    },
    reset: (state, mode) => {
      state[mode].status = false
      state[mode].title = ''
      state[mode].message = ''
      state[mode].details = ''
      state[mode].response = ''
    }
  }
}
