import Vue from 'vue'

export default {
  add (email) {
    return Vue.$http.post('v2/business/teammates/add', {email})
  },
  // eslint-disable-next-line camelcase
  remove (account_id) {
    // eslint-disable-next-line camelcase
    return Vue.$http.post('v2/business/teammates/remove', {account_id})
  },
}
