import Vue from 'vue'

export default {
  load () {
    return Vue.$http.get('v2/business/projects')
  },
  get (id) {
    return Vue.$http.get(`v2/business/projects/${id}`)
  },
  create (project) {
    return Vue.$http.post('v2/business/projects/add', project)
  },
  updateIndividual (project) {
    return Vue.$http.post(`v1/projects/${project.id}`, project)
  },
  updateBusiness (project) {
    return Vue.$http.put(`v2/business/projects/${project.id}`, project)
  },
  removeIndividual (id) {
    return Vue.$http.post(`v1/projects/${id}/delete`)
  },
  removeBusiness (id) {
    return Vue.$http.delete(`v2/business/projects/${id}`)
  },
  docs (id) {
    return Vue.$http.get(`v2/business/projects/${id}/docs`)
  },
  createDocument (documentType, data) {
    return Vue.$http.post(`/v2/business/docs/${documentType}`, data, {
      responseType: 'blob'
    })
  },
  downloadDocumentPdf (documentId) {
    return Vue.$http.get(`/v2/business/docs/${documentId}`, {
      responseType: 'blob'
    })
  },
  addLibs (projectId, data) {
    return Vue.$http.post(`/v2/business/projects/${projectId}/licenses`, {libList: data})
  },
  getLicenses (projectId) {
    return Vue.$http.get(`/v2/business/projects/${projectId}/licenses`)
  },
  stopTrackingLicense (projectId, libName) {
    return Vue.$http.post(`/v2/business/projects/${projectId}/licenses/stop`, {libName})
  },
  storeDependenciesFile (projectId, data) {
    return Vue.$http.post(`/v2/business/projects/${projectId}/dependencies`, data)
  },
  listDependencies (projectId) {
    return Vue.$http.get(`/v2/business/projects/${projectId}/dependencies`)
  },
}
