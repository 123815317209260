import {i18n} from '@/plugins/i18n'
import {RepositoryFactory} from '@/repositories/_repository-factory'

const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  namespaced: true,
  state: {
    plans: null,
    orders: {
      list: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
    },
    // TODO remove if not needed
    setupIntent: null,
    // TODO remove if not needed
    paymentMethods: []
  },
  getters: {
    // TODO remove if not needed
    getDefaultPaymentMethodId: ({paymentMethods}) => paymentMethods.find(pm => pm.default)?.id,
    getCustomPlans: ({plans}) => plans?.custom.plans,
    getIndividualPlans: ({plans}) => plans?.individual.plans,
  },
  mutations: {
    setPlans: (state, plans) => {
      state.plans = plans
    },
    setOrders: (state, orders) => {
      state.orders.list = orders
    },
    // eslint-disable-next-line camelcase
    setOrdersPagination: (state, {current_page, per_page, total}) => {
      // eslint-disable-next-line camelcase
      state.orders.pagination.currentPage = current_page
      // eslint-disable-next-line camelcase
      state.orders.pagination.perPage = per_page
      state.orders.pagination.total = total
    },
    // TODO remove if not needed
    setSetupIntent: (state, intent) => {
      state.setupIntent = {...intent}
    },
    // TODO remove if not needed
    setPaymentMethods: (state, methods) => {
      state.paymentMethods = methods
    },
    // TODO remove if not needed
    removePaymentMethod: (state, methodId) => {
      const i = state.paymentMethods.findIndex(pm => pm.id === methodId)
      if (i !== -1) state.paymentMethods.splice(i, 1)
    }
  },
  actions: {
    async loadPlans ({commit}) {
      const {data: {data}} = await subscriptionRepository.plans()
      commit('setPlans', data)
    },
    async loadOrderById (state, orderId) {
      /**
       * This api call forces the server to fetch payment status of the order from the payment system.
       * However, for now it returns 422 on attempt to pay for order, which isn't related to
       * currently active subscription plan, therefore it's response isn't saved anywhere.
       */
      await subscriptionRepository.getOrderById(orderId)
    },
    async loadOrders ({commit, dispatch, state: {orders: { list: ordersOld }}}, payload = {}) {
      const {data: {data: {data: ordersNew, ...pagination}}} = await subscriptionRepository.getOrders(payload)

      if (ordersOld) {
        for (const {id: idOld, status: statusOld} of ordersOld) {
          const {status: statusNew} = ordersNew.find(o => o.id === idOld) || {}
          // TODO better make possible status values consts
          if (statusNew && statusOld === 'pending' && statusOld !== statusNew) {
            if (statusNew === 'paid') {
              dispatch('notifyOfPaymentSuccess')
            } else {
              dispatch('notifyOfPaymentFail')
            }
          }
        }
      }

      commit('setOrders', ordersNew)
      commit('setOrdersPagination', pagination)
    },
    async getOrderPaymentLink (state, orderId) {
      const {data: {data: {confirmation_url: confirmationUrl}}} = await subscriptionRepository.getOrderPaymentLink(orderId)
      return confirmationUrl
    },
    notifyOfPaymentSuccess ({commit}) {
      commit(
        'notify/show',
        {text: i18n.t('Order payment success'), color: 'success'},
        {root: true}
      )
    },
    notifyOfPaymentFail ({commit}) {
      commit(
        'notify/show',
        {text: i18n.t('Order payment fail'), color: 'error'},
        {root: true}
      )
    },
    // TODO remove if not needed
    async setupIntent ({commit}) {
      const {data: {data}} = await subscriptionRepository.setupIntent()
      commit('setSetupIntent', data)
    },
    // TODO remove if not needed
    async loadPaymentMethods ({commit}) {
      const {data: {data}} = await subscriptionRepository.paymentMethods()
      commit('setPaymentMethods', data)
    },
    // TODO remove if not needed
    async removePaymentMethod ({commit}, methodId) {
      const result = await subscriptionRepository.removeMethod(methodId)
      commit('removePaymentMethod', methodId)
      return result
    },
    async createSubscription ({commit}, {planId}) {
      return subscriptionRepository.createSubscription(planId)
    },
    async cancelSubscription ({commit}, subscriptionId) {
      return subscriptionRepository.cancelSubscription(subscriptionId)
    }
  }
}
