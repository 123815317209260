import Vue from 'vue'

export default {
  getClassifiers () {
    return Vue.$http.get('v2/business/catalog/klassificator')
  },
  getCodes () {
    return Vue.$http.get('v2/business/catalog/okpd2')
  },
  getOwnershipReasons () {
    return Vue.$http.get('v2/business/catalog/basis')
  },
}
