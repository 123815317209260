import axios from 'axios'
import {Auth} from '@/plugins/auth'

export const axiosInstance = axios.create({
  baseURL: window.env.API_BASE_URL || 'http://localhost/api'
})

axiosInstance.interceptors.request.use(
  (req) => {
    const authHeader = Auth.getAuthorizationHeader(req.url)
    if (authHeader) {
      req.headers.Authorization = authHeader
    }

    return req
  },
  (error) => Promise.reject(error)
)

export const AxiosPlugin = {
  install (Vue) {
    Vue.prototype.axios = axiosInstance
    Vue.prototype.$http = axiosInstance
    Vue.axios = axiosInstance
    Vue.$http = axiosInstance
  }
}
