import {RepositoryFactory} from '@/repositories/_repository-factory'

const searchCodeRepository = RepositoryFactory.get('searchCode')

export default {
  namespaced: true,
  actions: {
    async search ({commit, state}, params) {
      let {data: {data}} = await searchCodeRepository.search(params)
      return data
    },
  }
}
