export const light = {
  primary: {
    base: '#A08AE4',
    lighten1: '#dddddd'
  },
  secondary: {
    base: '#0f0f0f',
    darken1: '#000000',
    lighten1: '#5a5a89',
    lighten2: '#9a9db7'
  },
  background: {
    base: '#f9f8f8'
  },
  surface: {
    base: '#ffffff',
    darken1: '#f5f7fd'
  },
  avatar: {
    base: '#cfdaf9'
  },
  text: {
    base: '#252525',
    lighten1: '#777777'
  },
  outline: {
    base: '#dddddd'
  },
  error: '#f04304'
}
