<template>
  <v-overlay
    :value="error"
    data-cy="error-global"
    opacity="0.8"
    z-index="10"
    absolute
  >
    <div class="d-flex">
      <div class="my-1 mx-3">
        <v-icon x-large>
          {{ icons.alert }}
        </v-icon>
      </div>
      <div class="mr-3">
        <h1>{{ title }}</h1>
        <p>{{ message }}</p>
        <p>{{ $t('Reload page or try again later') }}</p>

        <p v-if="showDebugInfo" class="text-caption mb-0 debug">
          Debug info:
          <br>
          {{ details }}
        </p>
        <pre class="debug" v-html="response" />
      </div>
    </div>
  </v-overlay>
</template>

<script>
import {mapState} from 'vuex'
import {mdiAlertCircleOutline} from '@mdi/js'

export default {
  name: 'ErrorGlobal',
  data () {
    return {
      icons: {
        alert: mdiAlertCircleOutline
      }
    }
  },
  computed: {
    ...mapState({
      error: state => state.error.global.status,
      title: state => state.error.global.title,
      message: state => state.error.global.message,
      details: state => state.error.global.details,
      response: state => state.error.global.response,
    }),
    showDebugInfo () {
      return window.env.STAGE === 'local'
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  max-width: 100ch;
}
.debug {
  color: #f7d862;
}
pre {
  font-family: '.SF NS Mono', Menlo, Monaco, monospace;
  font-size: 0.75rem;
  line-height: 1;
  max-width: 95vw;
  max-height: 50vh;
  overflow: scroll;
}
</style>
