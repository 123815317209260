import {RepositoryFactory} from '@/repositories/_repository-factory'

const catalogRepository = RepositoryFactory.get('catalog')

export default {
  namespaced: true,
  state: {
    classifiers: [],
    codes: {},
    ownershipReasons: {},
  },
  mutations: {
    setClassifiers: (state, classifiers) => {
      state.classifiers = classifiers
    },
    setCodes: (state, codes) => {
      state.codes = codes
    },
    setOwnershipReasons: (state, reasons) => {
      state.ownershipReasons = reasons
    },
  },
  actions: {
    async getClassifiers ({commit}) {
      const {data} = await catalogRepository.getClassifiers()
      commit('setClassifiers', data)
    },
    async getCodes ({commit}) {
      const {data} = await catalogRepository.getCodes()
      commit('setCodes', data)
    },
    async getOwnershipReasons ({commit}) {
      const {data} = await catalogRepository.getOwnershipReasons()
      commit('setOwnershipReasons', data)
    },
  }
}
