import Vue from 'vue'

export default {
  profile () {
    return Vue.$http.get('v1/users/profile')
  },
  update (data) {
    return Vue.$http.post('v1/users', data)
  },
  sendEnquiry (data) {
    return Vue.$http.post('v2/business/enquiry', data)
  },
  getEnquiryDetails (id) {
    return Vue.$http.get(`v2/business/enquiry/${id}`)
  },
}
