import Vue from 'vue'
import qs from 'qs'

export default {
  plans () {
    return Vue.$http.get('v2/business/plans')
  },
  // TODO should be moved to separate repo
  getOrderById (orderId) {
    return Vue.$http.get(`/v2/business/orders/${orderId}`)
  },
  // TODO should be moved to separate repo
  getOrders (params) {
    return Vue.$http.get('/v2/business/orders/', {
      params,
      paramsSerializer (params) {
        return qs.stringify(params, {encode: false})
      }
    })
  },
  // TODO should be moved to separate repo
  getOrderPaymentLink (orderId) {
    return Vue.$http.post(`/v2/business/orders/${orderId}/pay`)
  },
  // TODO remove if not needed
  setupIntent () {
    return Vue.$http.get('v1/payments/stripe/setup-intent')
  },
  // TODO remove if not needed
  paymentMethods () {
    return Vue.$http.get('v1/payments/stripe/methods')
  },
  // TODO remove if not needed
  removeMethod (paymentMethodId) {
    return Vue.$http.post('v1/payments/stripe/method/remove', {payment_method_id: paymentMethodId})
  },
  createSubscription (planId) {
    return Vue.$http.post('v2/business/subscriptions', {plan_id: planId})
  },
  cancelSubscription (subscriptionId) {
    return Vue.$http.post('v2/business/subscriptions/cancel', {subscription_id: subscriptionId})
  }
}
