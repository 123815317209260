import Vue from 'vue'
import {RepositoryFactory} from '@/repositories/_repository-factory'
import {makeFormData} from '@/util'

const projectsRepository = RepositoryFactory.get('projects')

export default {
  namespaced: true,
  state: {
    projects: [],
    projectsLoaded: false,
  },
  getters: {
    getProjectById: state => id => {
      return state.projects.find(p => p.id === id * 1)
    },
    getNameById: (state, getters) => projectId => {
      return getters.getProjectById(projectId)?.name
    },
  },
  mutations: {
    setLoaded: (state, loaded) => {
      state.projectsLoaded = loaded
    },
    setProjects: (state, projects) => {
      state.projects = projects
    },
    create: (state, project) => {
      state.projects.push(project)
    },
    update: (state, project) => {
      const i = state.projects.findIndex(p => p.id === project.id * 1)
      if (i === -1) {
        state.projects.push(project)
      } else {
        for (const [key, value] of Object.entries(project)) {
          state.projects[i][key] = value
        }
      }
    },
    remove: (state, id) => {
      const i = state.projects.findIndex(p => p.id === id * 1)
      if (i !== -1) state.projects.splice(i, 1)
    },
    setDocuments: (state, {id, documents}) => {
      const i = state.projects.findIndex(p => p.id === id * 1)
      if (i !== -1) {
        Vue.set(state.projects[i], 'documents', documents)
      }
    },
    setLicenses: (state, {id, licenses, mode, source, status}) => {
      const i = state.projects.findIndex(p => p.id === id * 1)
      if (i !== -1) {
        Vue.set(state.projects[i], mode, licenses)

        if (source) {
          Vue.set(state.projects[i], 'dependenciesSource', source)
          Vue.set(state.projects[i], 'dependenciesStatus', status)
        }
      }
    },
    addLicenses: (state, {id, licenses}) => {
      const i = state.projects.findIndex(p => p.id === id * 1)
      if (i !== -1) {
        if (state.projects[i].licensesManual) {
          for (const license of licenses) {
            const j = state.projects[i].licensesManual.findIndex(l => l.id === license.id)
            if (j === -1) {
              state.projects[i].licensesManual.push(license)
            } else {
              Vue.set(state.projects[i].licensesManual, j, license)
            }
          }
        } else {
          Vue.set(state.projects[i], 'licensesManual', licenses)
        }
      }
    },
    updateLicense: (state, license) => {
      const i = state.projects.findIndex(p => p.id === license.project_id)
      if (i !== -1) {
        const j = state.projects[i].licensesManual.findIndex(l => l.id === license.id)
        if (j !== -1) {
          Vue.set(state.projects[i].licensesManual, j, license)
        }
      }
    },
  },
  actions: {
    async load ({commit}) {
      const {data: {data}} = await projectsRepository.load()
      commit('setProjects', data)
      commit('setLoaded', true)
    },
    async get ({commit}, id) {
      const {data: {data}} = await projectsRepository.get(id)
      commit('update', data)
    },
    async create ({commit}, project) {
      const {data: {data}} = await projectsRepository.create(project)
      commit('create', data)
      return data.id
    },
    async updateIndividual ({commit}, project) {
      const {data: {data}} = await projectsRepository.updateIndividual(project)
      commit('update', data)
    },
    async updateBusiness ({commit}, project) {
      const {data: {data}} = await projectsRepository.updateBusiness(project)
      commit('update', data)
    },
    async removeIndividual ({commit}, id) {
      await projectsRepository.removeIndividual(id)
      commit('remove', id)
    },
    async removeBusiness ({commit}, id) {
      await projectsRepository.removeBusiness(id)
      commit('remove', id)
    },
    async loadDocuments ({commit}, id) {
      const {data: {data}} = await projectsRepository.docs(id)
      commit('setDocuments', {id, documents: data})
    },
    async createDocument ({commit}, {type, doc}) {
      const {data} = await projectsRepository.createDocument(type, doc)
      return data
    },
    prDownloadDocumentPdf ({commit}, id) {
      return projectsRepository.downloadDocumentPdf(id)
    },
    async prAddLibs ({commit}, {id, libs}) {
      const {data: {data}} = await projectsRepository.addLibs(id, libs)
      commit('addLicenses', {id, licenses: data})
    },
    async getLicenses ({commit}, id) {
      const {data: {data}} = await projectsRepository.getLicenses(id)
      commit('setLicenses', {id, licenses: data, mode: 'licensesManual'})
    },
    async prStopTrackingLicense ({commit}, {id, libName}) {
      const {data: {data}} = await projectsRepository.stopTrackingLicense(id, libName)
      commit('updateLicense', data)
    },
    async prStoreDependenciesFile ({commit}, {id, form}) {
      const {data} = await projectsRepository.storeDependenciesFile(id, makeFormData(form))
      return data
    },
    async prListDependencies ({commit}, id) {
      const {data} = await projectsRepository.listDependencies(id)
      if (data.result) {
        let {dependencies, source, status} = data.data

        let complete = dependencies.complete
        if (complete === undefined) {
          complete = -1
        }

        dependencies = Object.entries(dependencies)
          .filter(([key]) => key !== 'complete')
          .map(([, val]) => val)

        dependencies?.sort((a, b) => {
          const nameA = a.owner?.toUpperCase() + a.name?.toUpperCase()
          const nameB = b.owner?.toUpperCase() + b.name?.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
        commit('setLicenses', {
          id,
          licenses: dependencies,
          mode: 'licensesAuto',
          source,
          status
        })
        return {result: true, complete}
      } else {
        return data
      }
    },
  }
}
