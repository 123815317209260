import Vue from 'vue'

export default {
  getApplications (depositId) {
    return Vue.$http.get(`/v2/business/applications?deposit=${depositId}`)
  },
  addApplication (application) {
    return Vue.$http.post('v2/business/applications', application)
  },
  updateApplication (id, application) {
    return Vue.$http.post(`v2/business/applications/${id}`, application)
  },
  getApplicationDocuments (id) {
    return Vue.$http.get(`/v2/business/applications/${id}/documents`)
  },
  saveApplicationDocument (id, {file, type}) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    return Vue.$http.post(`/v2/business/applications/${id}/documents`, formData)
  }
}
