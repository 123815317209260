<template>
  <div>
    <v-app-bar
      absolute
      app
      dark
      flat
      prominent
    >
      <!-- mobile menu open button and logo -->
      <v-container class="py-0 fill-height hidden-sm-and-up">
        <v-row align="center" class="fill-height" no-gutters>
          <v-col cols="auto">
            <v-btn
              v-if="ready"
              :ripple="false"
              fab
              small
              text
              @click.stop="drawer = !drawer"
            >
              <v-icon v-text="icons.menu" />
            </v-btn>
          </v-col>
          <v-spacer />
          <app-logo />
        </v-row>
      </v-container>

      <!-- tablet and desktop nav -->
      <v-container class="py-0 fill-height hidden-xs-only">
        <v-row align="stretch" class="fill-height flex-nowrap" no-gutters>
          <app-logo />

          <!-- nav buttons -->
          <v-col class="d-flex hidden-sm-and-down">
            <v-btn
              v-if="ready"
              :ripple="false"
              :to="{name: 'projects'}"
              active-class="nav-button-active"
              class="ml-2 ml-md-5 nav-button align-self-stretch"
              text
            >
              {{ $t('Projects') }}
            </v-btn>
            <v-btn
              v-if="ready"
              :ripple="false"
              :to="{name: 'deposits', params: {projectId: 'all', sectionId: 'active'}}"
              :class="{'nav-button-active': $route.name && $route.name.startsWith('deposit')}"
              class="nav-button"
              text
            >
              {{ $t('Deposits') }}
            </v-btn>
            <v-btn
              v-if="ready"
              :ripple="false"
              :to="{name: 'search-code' }"
              :class="{'nav-button-active': $route.name && $route.name.startsWith('search-code')}"
              class="nav-button"
              text
            >
              {{ $t('Search code') }}
            </v-btn>
            <!-- TODO uncomment or remove when application requrements are finalized -->
            <!--
            <v-btn
              v-if="ready && showApplication"
              :ripple="false"
              :to="{name: 'application'}"
              active-class="nav-button-active"
              class="nav-button align-self-stretch"
              text
            >
              {{ $t('Application') }}
            </v-btn>
            -->
            <template v-if="showAddDepositButton">
              <v-btn
                :ripple="false"
                :to="{name: 'new-deposit-select-type'}"
                class="ml-5 align-self-center hidden-sm-and-down btn-bg-gradient"
                color="primary"
                rounded
              >
                {{ $t('Add new deposit') }}
              </v-btn>
              <v-btn
                :ripple="false"
                :title="$t('Add new deposit')"
                :to="{name: 'new-deposit-select-type'}"
                class="ml-5 align-self-center hidden-md-and-up btn-bg-gradient"
                color="primary"
                fab
              >
                <v-icon v-text="icons.plus" />
              </v-btn>
            </template>
          </v-col>

          <v-spacer class="hidden-xs-only" />

          <!-- user and plan -->
          <v-col class="d-md-flex px-2 px-md-5 hidden-sm-and-down" cols="auto">
            <div v-if="ready && profileLoaded" class="profile-info align-self-center body-2">
              <div class="profile-name">
                {{ profile.name }}
              </div>
              <template v-if="isBusinessAccount">
                <div class="profile-role">
                  {{ roleTitle }}
                </div>
                <div class="profile-company mt-2">
                  {{ profile.company.name }}
                </div>
              </template>
            </div>
          </v-col>

          <!-- avatar and menu -->
          <v-col class="d-flex pl-2 pl-md-5 hidden-sm-and-down" cols="auto">
            <v-menu
              v-if="ready"
              bottom
              close-on-click
              content-class="profile-menu"
              left
              nudge-bottom="90"
              open-delay="300"
              open-on-hover
            >
              <template #activator="{on}">
                <v-btn
                  :ripple="false"
                  class="nav-avatar-button align-self-center pr-0"
                  text
                  v-on="on"
                  @click="gotoProfile"
                >
                  <app-avatar :image="profile.avatar" size="46" />
                  <v-icon right>
                    {{ icons.menuDown }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list class="py-0">
                <v-list-item
                  :ripple="false"
                  class="hidden-md-and-up profile-name"
                  disabled
                >
                  {{ profile.name }}
                </v-list-item>

                <v-list-item
                  :ripple="false"
                  :to="{name: 'profile'}"
                  class="text-decoration-none"
                >
                  {{ $t('Profile') }}
                </v-list-item>

                <v-list-item
                  :ripple="false"
                  :to="{name: 'profile-password'}"
                  class="text-decoration-none"
                >
                  {{ $t('Change password') }}
                </v-list-item>
                <v-list-item
                  v-if="showSubscriptions"
                  :ripple="false"
                  :to="{name: 'profile-subscription'}"
                  class="text-decoration-none"
                >
                  {{ $t('Manage subscription') }}
                </v-list-item>
                <v-list-item
                  v-if="showCompany"
                  :ripple="false"
                  :to="{name: 'profile-company'}"
                  class="text-decoration-none"
                >
                  {{ $t('Company info') }}
                </v-list-item>
                <v-list-item
                  v-if="showTeammates"
                  :ripple="false"
                  :to="{name: 'profile-teammates'}"
                  class="text-decoration-none"
                >
                  {{ $t('Manage teammates') }}
                </v-list-item>

                <v-list-item
                  :ripple="false"
                  class="exit-link"
                  @click="doLogout"
                >
                  {{ $t('Logout') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- mobile nav drawer -->
    <v-navigation-drawer
      v-if="ready"
      :value="drawer"
      mobile-breakpoint="xs"
      app
      temporary
      clipped
      @input="drawerInput"
    >
      <v-list>
        <v-list-item
          v-if="profileLoaded"
          :ripple="false"
          :to="{name: 'profile'}"
          class="text-decoration-none"
        >
          <app-avatar :image="profile.avatar" class="mr-2" size="32" />
          <div>
            <div>{{ profile.name }}</div>
            <template v-if="isBusinessAccount">
              <div class="profile-role">
                {{ roleTitle }}
              </div>
            </template>
          </div>
        </v-list-item>

        <v-divider class="mb-4" />

        <v-list-item class="mb-4">
          <v-btn
            :disabled="$route.name === 'new-deposit-select-type'"
            :ripple="false"
            :to="{name: 'new-deposit-select-type'}"
            class="white--text btn-bg-gradient"
            color="primary"
            elevation="0"
            block
            rounded
          >
            {{ $t('Add new deposit') }}
          </v-btn>
        </v-list-item>

        <v-list-item class="px-0">
          <v-tabs :hide-slider="notInVerticalTabsRoutes" optional vertical>
            <v-tab
              :ripple="false"
              :to="{name: 'projects'}"
              class="justify-start text-decoration-none"
            >
              {{ $t('Projects') }}
            </v-tab>
            <v-tab
              :ripple="false"
              :to="{name: 'deposits', params: {projectId: 'all', sectionId: 'active'}}"
              class="justify-start text-decoration-none"
            >
              {{ $t('Deposits') }}
            </v-tab>
            <v-tab
              :ripple="false"
              :to="{name: 'profile'}"
              class="justify-start text-decoration-none"
            >
              {{ $t('Profile') }}
            </v-tab>
          </v-tabs>
        </v-list-item>

        <v-list-item :ripple="false" class="mt-4" @click="doLogout">
          <v-icon left v-text="icons.logout" />
          {{ $t('Logout') }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {mdiLogout, mdiMenu, mdiMenuDown, mdiPlus} from '@mdi/js'
import AppAvatar from '@/components/AppAvatar'
import AppLogo from '@/components/AppLogo'

export default {
  name: 'AppNavUser',
  components: {AppLogo, AppAvatar},
  data () {
    return {
      loggingOut: false,
      drawer: false,
      icons: {
        logout: mdiLogout,
        menu: mdiMenu,
        menuDown: mdiMenuDown,
        plus: mdiPlus
      }
    }
  },
  computed: {
    ...mapState({
      ready: state => state.ready,
      profileLoaded: state => state.loaders.profile.status,
      profile: state => state.profile,
      isBusinessAccount: state => state.profile.accountType === 'business',
      currentPage: state => state.deposits.pagination.currentPage,
    }),
    ...mapGetters({
      activePlan: 'getActivePlan',
      showSubscriptions: 'showSubscriptions'
    }),
    showAddDepositButton () {
      return this.ready &&
          this.profileLoaded &&
          this.$route.name !== 'new-deposit' &&
          this.$route.name !== 'new-deposit-select-type' &&
          this.profile.accountType
    },
    notInVerticalTabsRoutes () {
      if (!this.$route.name) return true
      return !this.$route.name.startsWith('deposit') &&
        !this.$route.name.startsWith('project') &&
        !this.$route.name.startsWith('profile')
    },
    roleTitle () {
      if (!this.isBusinessAccount) return ''
      return this.profile.role === 'manager' ? this.profile.role_title : ''
    },
    showTeammates () {
      return this.isBusinessAccount && this.profile.role === 'manager'
    },
    showCompany () {
      return this.isBusinessAccount && this.profile.role === 'manager'
    },
    showApplication () {
      return this.profile.role !== 'teammate'
    },
  },
  methods: {
    ...mapActions(['logout']),
    async doLogout () {
      this.loggingOut = true
      await this.$auth.logout()
      this.loggingOut = false
    },
    drawerInput (open) {
      this.drawer = open
    },
    gotoProfile () {
      if (this.$route.name !== 'profile') {
        this.$router.push({name: 'profile'})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  header .v-toolbar__content {
    .nav-button {
      padding-left: 25px !important;
      padding-right: 25px !important;
      opacity: 0.42;
      height: 100%;

      &::before, &:hover::before {
        opacity: 0;
        background-color: transparent;
        border-bottom: 4px solid var(--v-primary-base);
      }

      &.nav-button-active {
        opacity: 1;

        &::before, &:hover::before {
          opacity: 1;
        }
      }
    }

    .nav-avatar-button {
      height: 100%;

      &::before, &:hover::before {
        opacity: 0;
        background-color: transparent;
      }
    }

    .profile-info {
      max-width: 15ch;
      max-height: 7rem;
      overflow: hidden;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: 50ch;
      }
    }
  }

  .profile-name {
    word-break: break-word;
  }

  .profile-role,
  .profile-company {
    line-height: 1.2;
    font-size: 80%;
  }

  .profile-menu {
    box-shadow: none;
    border: thin solid var(--v-outline-base);

    .v-list-item {
      &.profile-name {
        border-bottom: thin solid var(--v-outline-base);
      }
      &.exit-link {
        border-top: thin solid var(--v-outline-base);
      }

      &:hover {
        background-color: var(--v-surface-darken1);

        &::before {
          opacity: 0;
        }
      }
    }
  }
</style>
