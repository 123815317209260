<template>
  <v-dialog
    v-model="error"
    max-width="360"
    @click:outside="reset('modal')"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <p v-html="message" />
        <p><small v-html="details" /></p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :ripple="false"
          color="error"
          text
          @click="reset('modal')"
        >
          {{ $t('Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'ErrorModal',
  computed: {
    ...mapState({
      error: state => state.error.modal.status,
      title: state => state.error.modal.title,
      message: state => state.error.modal.message,
      details: state => state.error.modal.details,
    }),
  },
  methods: {
    ...mapMutations({
      reset: 'error/reset',
    }),
  },
}
</script>

<style lang="scss" scoped>
</style>
