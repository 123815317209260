<template>
  <footer>
    <div class="footer-top">
      <app-logo footer />
      <div class="spacer"></div>
      <div v-if="false">
        <a
          :href="`tel:${info.contacts.phone.format.link}`"
          @click="$gtm.trackEvent({event: 'click-on-contacts'})"
          v-html="info.contacts.phone.format.text"
        />
      </div>
      <div>
        <a
          :href="`mailto:${info.contacts.mail}`"
          @click="$gtm.trackEvent({event: 'click-on-contacts'})"
          v-text="info.contacts.mail"
        /><br/>
        <a
          :href="`tel:${info.contacts.phone.format.link}`"
          @click="$gtm.trackEvent({event: 'click-on-contacts'})"
          v-html="info.contacts.phone.format.text"
        />
      </div>
    </div>

    <div class="footer-social">
      <div v-if="verified" class="block-with-links">
        <div>Скачать плагин</div>
        <div class="links">
          <a
            v-for="(plugin, key) in plugins"
            :key="`plugin-${key}`"
            :href="plugin.url"
            class="icon-container"
            download
          >
            <v-icon v-text="icons[key]" />
          </a>
        </div>
      </div>
      <div v-else></div>

      <div class="block-with-links">
        <div>Мы в&nbsp;социальных сетях</div>
        <div class="links">
          <a
            v-for="social in socials"
            :key="`social-${social.network}`"
            :href="social.url"
            class="icon-container"
            rel="nofollow noopener"
          >
            <v-icon v-text="icons[social.network]" />
          </a>
        </div>
      </div>
    </div>

    <div class="footer-copyrights">
      <div>{{ info.copyright }}</div>
      <div class="spacer"></div>
      <p>
        <a :href="privacy" target="_blank">
          Политика конфиденциальности
        </a>
      </p>
      <p>
        <a :href="copyright" target="_blank">
          Пользовательское соглашение
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
import {mapState} from 'vuex'
import {mdiFigmaOutline, mdiJetbrains, mdiTelegram, mdiVk} from '@/assets/mdi-custom-icons'
import AppLogo from '@/components/AppLogo'

export default {
  name: 'AppFooter',
  components: {AppLogo},
  data () {
    return {
      icons: {
        figma: mdiFigmaOutline,
        jetbrains: mdiJetbrains,
        telegram: mdiTelegram,
        vk: mdiVk,
      },
    }
  },
  computed: mapState({
    plugins: state => ({
      figma: state.config.app?.figma || {},
      jetbrains: state.config.app?.jetbrains || {},
    }),
    socials: state => {
      return Object.entries(state.config.info?.contacts?.socials || {})
        .filter(([network, url]) => url)
        .map(([network, url]) => ({network, url}))
    },
    info: state => state.config.info,
    verified: state => state.profile.verified,
    copyright () {
      return this.info.terms
    },
    privacy () {
      return this.info.privacy
    }
  }),
}
</script>

<style lang="scss">
.v-application footer {
  --container-padding-x: 1.25rem;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    --container-padding-x: 2rem;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    --container-padding-x: 3.75rem;
  }
  --border-dark: #333333;
  display: block;
  background-color: var(--v-secondary-darken1);
  color: var(--v-surface-base);

  .spacer {
    flex-grow: 1;
  }

  .gap-2 {
    gap: 1rem;
  }

  .align-items-center {
    align-items: center;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--border-dark);
    transition: all 0.3s ease;

    & > .v-icon {
      color: #000;
    }

    &:hover > .v-icon {
      color: #fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      border-radius: inherit;
      background: var(--gradient);
      transition: all 0.3s ease;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  .footer-top {
    --padding-top: 1.5rem;
    --padding-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.25rem;
    padding: var(--padding-top) var(--container-padding-x) var(--padding-bottom);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    border-bottom: 1px solid var(--border-dark);
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      --padding-top: 1.5rem;
      --padding-bottom: 2.5rem;
      flex-direction: row;
      flex-basis: 0;
      gap: 3rem;
    }

    & a {
      color: var(--v-surface-base);

      &:hover {
        text-decoration: none;
      }
    }
  }

  .footer-social {
    --padding: 1.875rem;
    font-weight: 500;
    padding: var(--padding) var(--container-padding-x);
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border-dark);
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    & > .block-with-links {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      & > .links {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .footer-copyrights {
    --padding: 1.25rem;
    color: #d9dbe1;
    padding: var(--padding) var(--container-padding-x);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex-direction: row;
      flex-basis: 0;
      flex-wrap: nowrap;
      gap: 2.5rem;
    }

    & > div:first-child,
    & p {
      margin-bottom: 1rem;
      font-weight: 400;
      font-size: 0.875rem;
    }

    a {
      color: #d9dbe1;
    }
  }
}
</style>
